import React, {useMemo} from "react";

import {useAuth} from "oidc-react";

import {getWindowManager, WindowManager} from "./providers/WindowManager";
import {LoginView} from "./components/LoginView";
import {Desktop} from "./components/Desktop";
import {WindowContainer} from "./components/WindowContainer";
import {WindowSettings} from "./components/Window";
import {SearchBar} from "./components/SearchBar";
import {useLocalStorage} from "./utils/Hooks";

import "modern-normalize/modern-normalize.css"
import "./style/ui.css";

function App() {

  const [windows, setWindows] = useLocalStorage<WindowSettings[]>("windows", []);
  const windowContext = useMemo(() => getWindowManager(windows, setWindows), [windows, setWindows]);

  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <p style={{position: "absolute", color: "white"}}>
        Loading...
      </p>
    );
  }

  if (!auth.userData) {
    return <LoginView />;
  }

  return (
    <WindowManager.Provider value={windowContext}>
      <Desktop />
      <WindowContainer />
      <SearchBar />
    </WindowManager.Provider>
  );

}

export default App;
