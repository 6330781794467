import React from "react";
import ReactDOM from "react-dom/client";

import {AuthProvider} from "oidc-react";
import {AuthProviderProps} from "oidc-react/build/src/AuthContextInterface";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {QueryClient, QueryClientProvider} from "react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      retryDelay: 1.5 * 1000,
      refetchInterval: 15 * 1000,
    },
  },
});

const oidcConfig: AuthProviderProps = {
  authority: "https://id.mntcrl.it/realms/master",
  clientId: "api-dashboard",
  clientSecret: "kt2dKUH1W3rYYaA6RW5kbkeddVnyWXTe",
  loadUserInfo: false,
  autoSignIn: false,
  responseType: "code",
  redirectUri: window.location.href,
  onSignIn: () => {
    window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
  },
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
