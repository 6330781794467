import React, {useContext, useRef, useState} from "react";
import {useGlobalListener} from "../utils/Hooks";
import {WindowManager} from "../providers/WindowManager";

export function SearchBar() {

  const [lastPress, setLastPress] = useState(0);
  const [open, setOpen] = useState(false);
  const windowManager = useContext(WindowManager);

  const ref = useRef<HTMLInputElement>(null);

  useGlobalListener("keydown", (event) => {
    if (event.key === "Shift") {
      if (Date.now() - lastPress < 500) {
        setOpen(true);
        setTimeout(() => ref.current?.focus(), 50);
      } else {
        setLastPress(Date.now);
      }
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  });

  const close = () => {
    if (!ref.current) return;
    ref.current.value = "";
    setOpen(false);
  }

  const keyPress = (event: React.KeyboardEvent) => {
    if (!ref.current) return;
    if (event.key === "Enter") {
      const text = ref.current.value;
      windowManager.createWindow(text);
      close();
    }
  }

  return (
    <div id="search-bar" className={open ? undefined : "invisible"}>
      <input type="text" placeholder="Search..." ref={ref} onBlur={close} onKeyDown={keyPress} />
    </div>
  );

}
