import React from "react";
import {v4 as randomUUID} from "uuid";

import {WindowSettings} from "../components/Window";

export type WindowSettingsUpdate = Partial<Omit<WindowSettings, "id">>;

export interface IWindowManager {
  getWindows: () => WindowSettings[];
  createWindow: (url: string, title?: string) => void;
  focusWindow: (id: string) => void;
  updateWindow: (id: string, update: WindowSettingsUpdate) => void;
  closeWindow: (id: string) => void;
}

export const WindowManager = React.createContext<IWindowManager>({} as IWindowManager);

export const WindowId = React.createContext<string>("");

export function getWindowManager(windows: WindowSettings[], setWindows: React.Dispatch<React.SetStateAction<WindowSettings[]>>): IWindowManager {

  const getWindows = () => {
    return windows;
  };

  const createWindow = (url: string, title: string = "") => {
    setWindows((windows) => [
      ...windows,
      {
        id: randomUUID(), title, url,
        position: {x: 150, y: 150},
        size: {width: 0, height: 0},
        maximized: false,
      },
    ]);
  };

  const focusWindow = (id: string) => {
    const index = windows.findIndex((settings) => settings.id === id);
    if (index >= 0 && index !== windows.length - 1) setWindows((windows) => {
      const updatedWindows = windows.slice();
      const [window] = updatedWindows.splice(index, 1);
      updatedWindows.push(window);
      return updatedWindows;
    });
  };

  const updateWindow = (id: string, update: WindowSettingsUpdate) => {
    const index = windows.findIndex((settings) => settings.id === id);
    if (index >= 0) setWindows((windows) => {
      const updatedWindows = windows.slice();
      updatedWindows[index] = updateWindowSettings(updatedWindows[index], update);
      return updatedWindows;
    });
  };

  const closeWindow = (id: string) => {
    const index = windows.findIndex((settings) => settings.id === id);
    if (index >= 0) setWindows((windows) => {
      const updatedWindows = windows.slice();
      updatedWindows.splice(index, 1);
      return updatedWindows;
    });
  };

  return  {
    getWindows,
    createWindow,
    focusWindow,
    updateWindow,
    closeWindow,
  };

}

function updateWindowSettings(original: WindowSettings, update: WindowSettingsUpdate): WindowSettings {
  return {
    ...original,
    ...update,
  };
}
