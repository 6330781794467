import {useAuth} from "oidc-react";

import logo from "../img/logo.png";
import "../style/login.css";

export function LoginView() {

  const auth = useAuth();

  return (
    <div id="login-wrapper">
      <div id="login-view">
        <img alt="logo" className="logo" src={logo} />
        <button onClick={() => auth.signIn()}>
          <span>Login</span>
        </button>
      </div>
    </div>
  );

}
