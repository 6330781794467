import {useQuery} from "react-query";

import {useApi, useTitle} from "../utils/Hooks";
import {CSSProperties} from "react";

const PAGE_NAME_PATTERN = /^[a-z0-9]+$/;

export function PagesView() {

  useTitle("Pages");

  const {apiCall} = useApi();
  const pages = useQuery("pages", () => {
    return apiCall<PageInfo[]>("GET", "/v1/pages");
  });

  const isError = pages.failureCount > 3;

  if (isError) return <p>{`An error has occurred`}</p>;
  if (pages.isLoading) return <p>Loading...</p>;

  return (
    <table style={{borderSpacing: 0, width: "100%", padding: 10}}>
      <tbody>{ pages.data?.map(mapPage) }</tbody>
    </table>
  );

}

function mapPage(page: PageInfo) {

  const domainStyle: CSSProperties = {
    display: "inline-block",
    width: 15,
    padding: 5,
    aspectRatio: 1,
    borderRadius: "50%",
    marginRight: 5,
  }

  const activeStyle: CSSProperties = {
    ...domainStyle,
    backgroundColor: "green",
  };

  const pendingStyle: CSSProperties = {
    ...domainStyle,
    backgroundColor: "gold",
  };

  return (
    <tr key={page.id}>
      <td style={{border: "solid 1px black", padding: 5}}>{page.name}</td>
      <td style={{border: "solid 1px black", padding: 5}}>{page.lastUpdate?.toString()}</td>
      <td style={{border: "solid 1px black", padding: 5}}>
        {page.domains.map((domain) => (
          <div key={domain.name}>
            <div style={domain.status === "active" ? activeStyle : pendingStyle}></div>
            <a target="_blank" href={`https://${domain.name}`}>{domain.name}</a>
          </div>
        ))}
      </td>
    </tr>
  );
}

interface PageDomainInfo {
  name: string;
  status: "active" | "pending";
}

interface PageDeploymentInfo {
  id: string;
  environment: string;
  url: string;
  branch: string;
  hash: string;
  message: string;
  timestamp: Date;
}

interface PageInfo {
  id: string;
  name: string;
  owner: string;
  authorized: string[];
  domains: PageDomainInfo[];
  creation: Date;
  lastUpdate?: Date;
}

interface ExtendedPageInfo extends PageInfo {
  deployments: PageDeploymentInfo[];
}

interface CreatePageInfo extends Pick<PageInfo, "name"> {}
interface CreatePageDeploymentInfo extends Pick<PageDeploymentInfo, "branch" | "hash" | "message"> {
  assets: File;
}
