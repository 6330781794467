import React, {useContext} from "react";

import {WindowId, WindowManager} from "../providers/WindowManager";
import {Window} from "./Window";

export function WindowContainer() {

  const windowManager = useContext(WindowManager);
  const windows = windowManager.getWindows();

  return (
    <div id="window-container">
      {
        windows.map((settings) => (
          <WindowId.Provider value={settings.id} key={settings.id}>
            <Window key={settings.id} settings={settings} />
          </WindowId.Provider>
        ))
      }
    </div>
  );

}
