import {useQuery, useQueryClient} from "react-query";

import {useApi, useTitle} from "../utils/Hooks";
import {useCallback, useContext, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {VncScreen, VncScreenHandle} from "react-vnc";

import {VscDebugStart, VscDebugStop, VscDebugRestart} from "react-icons/vsc";
import {BsDisc, BsHdd, BsHddNetwork} from "react-icons/bs";
import {FaTerminal} from "react-icons/fa";
import {GoServer} from "react-icons/go";

import "../style/vm.css";
import {WindowManager} from "../providers/WindowManager";

export function VMsView() {

  useTitle("VMs");

  const windowManager = useContext(WindowManager);
  const {apiCall} = useApi();

  const vms = useQuery("vms", () => {
    return apiCall<VmInfo[]>("GET", "/v1/vm");
  });

  const volumes = useQuery("volumes", () => {
    return apiCall<VolumeInfo[]>("GET", "/v1/volumes");
  });

  const openConsole = useCallback((vm: VmInfo) => {

    const consoleUrl = `/vm/${vm.id}`;
    const consoleWindow = windowManager.getWindows()
      .find((window) => window.url === consoleUrl);

    if (consoleWindow) windowManager.focusWindow(consoleWindow.id);
    else windowManager.createWindow(consoleUrl, `Console ${vm.name}`);

  }, [windowManager]);

  const isError = vms.failureCount > 3 || volumes.failureCount > 3;

  if (isError) return <p>{`An error has occurred`}</p>;
  if (vms.isLoading || volumes.isLoading) return <p>Loading...</p>;

  return (
    <div className="vm-list">
      { vms.data!.map((vm) => mapVm(vm, volumes.data!, () => openConsole(vm))) }
    </div>
  );

}

export function VMView() {

  const params = useParams();
  const vmId = params["id"];

  const consoleRef = useRef<VncScreenHandle>(null);
  const [disableButtons, setDisableButtons] = useState(false);

  const {apiCall} = useApi();
  const queryClient = useQueryClient();

  const vm = useQuery(`vm.${vmId}`, () => {
    return apiCall<VmInfo>("GET", `/v1/vm/${vmId}`);
  }, { refetchInterval: 3000 });

  const vmConsole = useQuery(`vm.${vmId}.console`, () => {
    return apiCall<SocketProxyInfo>("GET", `/v1/vm/${vmId}/vnc`);
  }, { refetchInterval: (data) => data ? data.expiresIn * 1000 : 3000 });

  const action = useCallback((action: string) => {
    setDisableButtons(true);
    apiCall("PUT", `/v1/vm/${vmId}/${action}`)
      .then(() => queryClient.invalidateQueries())
      .finally(() => setDisableButtons(false))
      .catch(console.error);
  }, [apiCall, vmId, setDisableButtons, queryClient])

  const start = useCallback(() => action("start"), [action]);
  const stop = useCallback(() => action("stop"), [action]);
  const restart = useCallback(() => action("restart"), [action]);

  const isError = vm.failureCount > 3;

  if (isError) return <p>{`An error has occurred`}</p>;
  if (vm.isLoading) return <p>Loading...</p>;

  let apiEndpoint = process.env["REACT_APP_API_ENDPOINT"] || "https://api.mntcrl.it";
  if (apiEndpoint.endsWith("/")) apiEndpoint = apiEndpoint.substring(0, apiEndpoint.length - 1);

  const vmData = vm.data!;

  const hasConsole = ["Running", "Stopping"].includes(vmData.status);
  const proxyPath = hasConsole ? vmConsole.data?.proxyUrl ?? "" : "";
  const consoleUrl = apiEndpoint.replace("http", "ws") + proxyPath;

  const vncConsole = <VncScreen
    url={consoleUrl}
    ref={consoleRef}
    scaleViewport={true}
    style={{ width: "100%", height: "100%" }}
    loadingUI={<></>}
  />;

  return (
    <div className="vm-console-view">
      <div className="vm-controls">
        <span>{vmStatus(vmData)}</span>
        <div>
          <button disabled={disableButtons} onClick={start}><VscDebugStart/>Start</button>
          <button disabled={disableButtons} onClick={stop}><VscDebugStop/>Stop</button>
          <button disabled={disableButtons} onClick={restart}><VscDebugRestart/>Restart</button>
        </div>
        <span className="vm-hostname"><GoServer/>{vmData.hostname}</span>
      </div>
      <div className="vm-console">
        {proxyPath && vncConsole}
      </div>
    </div>
  );

}

function mapVm(vm: VmInfo, allVolumes: VolumeInfo[], openConsole: () => void) {

  const volumes = vm.volumes.map(
    (vol) => allVolumes.find((v) => v.id === vol.id)
  )
    .filter((vol) => vol)
    .map((vol) => vol!)
    .map(mapVolume);

  return (
    <div className="vm-box" key={vm.id}>
      <div className="vm-box-header">
        <span>{vmStatus(vm)}</span>
        <span className="vm-name">{vm.name}</span>
        <span className="vm-hostname">
          <GoServer />
          {vm.hostname}
        </span>
      </div>
      <div className="vm-info">
        <div className="vm-properties">
          <div className="vm-property">
            <span>Owner</span><div className="chip">{vm.owner}</div>
          </div>
          <div className="vm-property">
            <span>Created</span><div className="chip">{formatDate(vm.creation)}</div>
          </div>
          <div className="vm-property">
            <span>Memory</span><div className="chip">{vm.memory}</div>
          </div>
          <div className="vm-property">
            <span>Bootloader</span><div className="chip">{vm.bootloader.toUpperCase()}</div>
          </div>
          <div className="vm-property">
            <span>Bus</span><div className="chip">{vm.useVirtIO ? "VirtIO" : "SATA"}</div>
          </div>
        </div>
        <div className="vm-volumes">
          <div className="vm-property" style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div className="vm-volumes-header">
              <span>Volumes</span>
              <button onClick={openConsole}><FaTerminal/>Console</button>
            </div>
            <div className="vm-volumes-list">{volumes}</div>
          </div>
        </div>
      </div>
    </div>
  );

}

function mapVolume(volume: VolumeInfo) {

  return (
    <div className="vm-volume" key={volume.id}>
      {volume.status === "pending" && <div className="status" style={{background: "rgb(253, 216, 0)"}}/>}
      {volume.status === "bound" && <div className="status" style={{background: "rgb(90, 192, 90)"}}/>}
      {volume.type === "hdd" && <BsHdd />}
      {volume.type === "cd" && <BsDisc />}
      {volume.type === "fs" && <BsHddNetwork />}
      {volume!.name}
    </div>
  );

}

function vmStatus(vm: VmInfo) {

  let vmStatusColor = "rgb(0, 0, 0)";
  if (vm.status === "Running") vmStatusColor = "rgb(90, 192, 90)";
  else if (vm.status === "Stopped") vmStatusColor = "rgb(237, 89, 74)";
  else if (vm.status === "Starting" || vm.status === "Stopping") vmStatusColor = "rgb(253, 216, 0)";

  let vmStatus = vm.status;
  if (vmStatus.startsWith("Error")) {
    vmStatus = "Error";
  }

  return (
    <>
      <div className="status" style={{background: vmStatusColor}}/>
      {vmStatus}
    </>
  );

}

function formatDate(creation: string) {

  const creationDate = new Date(Date.parse(creation));

  const date = creationDate.getDate().toString().padStart(2, "0");
  const month = (creationDate.getMonth() + 1).toString().padStart(2, "0");
  const year = creationDate.getFullYear();

  const hours = creationDate.getHours().toString().padStart(2, "0");
  const minutes = creationDate.getMinutes().toString().padStart(2, "0");

  return `${date}/${month}/${year} ${hours}:${minutes}`

}

interface VmInfo {
  id: string;
  name: string;
  owner: string;
  authorized: string[];
  creation: string;
  memory: string;
  bootloader: string;
  useVirtIO: boolean;
  volumes: { id: string }[];
  hostname: string;
  status: string;
}

interface VolumeInfo {
  id: string;
  name: string;
  type: "hdd" | "cd" | "fs";
  status: "pending" | "bound";
}

interface CreateVmInfo extends Pick<VmInfo, "name"> {}

interface SocketProxyInfo {
  proxyUrl: string;
  expiresIn: number;
}
