import React, {CSSProperties, useCallback, useContext, useEffect, useRef, useState} from "react";
import {createMemoryRouter, RouterProvider} from "react-router-dom";

import {WindowManager, WindowSettingsUpdate} from "../providers/WindowManager";
import {useGlobalListener} from "../utils/Hooks";
import {routes} from "../utils/Routes";

export interface WindowSettings {
  id: string;
  title: string;
  url: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  maximized: boolean;
}

export function Window({settings}: {settings: WindowSettings}) {

  const [router] = useState(createMemoryRouter(routes, {}));
  const windowManager = useContext(WindowManager);

  const focusWindow = useCallback(() => {
    windowManager.focusWindow(settings.id);
  }, [settings.id, windowManager]);

  const updateSettings = useCallback((update: WindowSettingsUpdate) => {
    windowManager.updateWindow(settings.id, update);
  }, [settings.id, windowManager]);

  const closeWindow = useCallback(() => {
    windowManager.closeWindow(settings.id);
  }, [settings.id, windowManager]);

  useEffect(() => {
    return router.subscribe((state) => {
      updateSettings({
        url: state.location.pathname,
      });
    });
  }, [updateSettings, router]);

  if (router.state.location.pathname !== settings.url) {
    router.navigate(settings.url).catch(console.error);
  }

  const [animated, setAnimated] = useState(false);
  const [dragOffset, setDragOffset] = useState<number[]>([]);
  const windowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if (!windowRef.current) return;

    let browserWidth = document.body.clientWidth;
    let browserHeight = document.body.clientHeight;
    let firstNotification = true;

    const observer = new ResizeObserver((mutations) => {

      const mutation = mutations[0];

      if (settings.size.width === 0 || settings.size.height === 0) {
        updateSettings({
          size: {
            width: mutation.contentRect.width,
            height: mutation.contentRect.height,
          },
        });
      }

      if (firstNotification) {
        firstNotification = false;
        return;
      }

      if (document.body.clientWidth !== browserWidth) return;
      if (document.body.clientHeight !== browserHeight) return;
      if (settings.maximized || animated) return;
      if (!document.hasFocus()) return;

      const width = settings.size.width;
      const height = settings.size.height;

      const newWidth = mutation.contentRect.width;
      const newHeight = mutation.contentRect.height;

      if (newWidth === width && newHeight === height) return;

      if (settings.maximized) {
        updateSettings({
          position: {x: 0, y: 0},
          maximized: false,
        });
      }

      updateSettings({
        size: {
          width: mutation.contentRect.width,
          height: mutation.contentRect.height,
        },
      });

    });

    observer.observe(windowRef.current);
    return () => observer.disconnect();

  }, [windowRef, animated, settings, updateSettings]);

  useEffect(() => {
    if (dragOffset.length === 0) return () => {};
    const prevValue = document.body.style.userSelect;
    document.body.style.userSelect = "none";
    return () => document.body.style.userSelect = prevValue;
  }, [dragOffset]);

  let animation: CSSProperties["animation"];
  if (settings.maximized) {
    animation = `full-screen ${animated ? "0.3s" : "0s"} ease forwards`;
  } else if (animated) {
    animation = "windowed 0.3s ease forwards"
  }

  const windowStyle: CSSProperties = {
    left: settings.position.x,
    top: settings.position.y,
    width: settings.size.width,
    height: settings.size.height,
    resize: settings.maximized ? "none" : undefined,
    animation: animation,
  };

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {

    if (event.target instanceof HTMLElement && event.target.className.includes("button")) return;
    if (event.detail === 2) return toggleMaximize();

    const posX = settings.maximized ? 0 : settings.position.x;
    const posY = settings.maximized ? 0 : settings.position.y;

    const width = settings.maximized ? document.body.clientWidth : settings.size.width;
    const height = settings.maximized ? document.body.clientHeight : settings.size.height;

    setDragOffset([
      (event.clientX - posX) / width,
      (event.clientY - posY) / height,
    ]);

  };

  useGlobalListener("mousemove", (event) => {

    if (dragOffset.length === 0) return;
    if (event.movementX === 0 && event.movementY === 0) return;

    if (settings.maximized) {
      updateSettings({
        maximized: false,
      });
    }

    let left = event.clientX - (dragOffset[0] * settings.size.width);
    let top = event.clientY - (dragOffset[1] * settings.size.height);

    if (left < 0) left = 0;
    if (top < 0) top = 0;

    updateSettings({
      position: {x: left, y: top},
    });

  });

  useGlobalListener("mouseup", () => {
    if (dragOffset.length > 0) setDragOffset([]);
  });

  const toggleMaximize = () => {
    updateSettings({
      maximized: !settings.maximized,
    });
    setAnimated(true);
    setTimeout(() => setAnimated(false), 500);
  };

  return (
    <div className="window" style={windowStyle} ref={windowRef} onMouseDown={focusWindow}>
      <div className="window-header" onMouseDown={onMouseDown}>
        <span className="window-button" style={{backgroundColor: "#ED594A"}} onClick={closeWindow}></span>
        <span className="window-button" style={{backgroundColor: "#FDD800"}} onClick={toggleMaximize}></span>
        <span className="window-button" style={{backgroundColor: "#5AC05A"}}></span>
        <span className="window-title">{settings.title}</span>
      </div>
      <div className="window-content">
        <RouterProvider router={router} />
      </div>
    </div>
  );

}
