import React from "react";
import {NavLink, RouteObject} from "react-router-dom";
import {PagesView} from "../components/Pages";
import {VMsView, VMView} from "../components/VMs";
import {useTitle} from "./Hooks";

function TestContent() {

  useTitle("Test");

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
      <NavLink to="/test1">Test</NavLink>
    </div>
  );

}

function TestContent1() {

  useTitle("Test1");

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
      <NavLink to="/test">Test1</NavLink>
    </div>
  );

}

export const routes: RouteObject[] = [
  {
    path: "/pages",
    element: <PagesView />,
  },
  {
    path: "/vm",
    element: <VMsView />,
  },
  {
    path: "/vm/:id",
    element: <VMView />,
  },
  {
    path: "/test",
    element: <TestContent />,
  },
  {
    path: "/test1",
    element: <TestContent1 />,
  },
];
